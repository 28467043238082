import { PAGE_TITLE_DISPLAY_SETTINGS } from '../../constants/index.js';
import DisplaySettings from '../DisplaySettings/index.jsx';
import { MainContainer, PageTitle, SettingsContainer } from './style.js';

export default function ConfigContainer() {
  return (
    <MainContainer data-testid="app-main-container">
      <PageTitle variant="h3" data-testid="app-page-title">
        {PAGE_TITLE_DISPLAY_SETTINGS}
      </PageTitle>
      <SettingsContainer>
        <DisplaySettings />
      </SettingsContainer>
    </MainContainer>
  );
}
